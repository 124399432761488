import * as React from "react"
import { Link, graphql } from "gatsby"
import "../infoPage.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Image from "gatsby-image";
import girl3 from "../images/girls/3.png"
import girl17 from "../images/girls/17.png"
import girl21 from "../images/girls/21.png"
import girl15 from "../images/girls/15.png"


const InfoPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="お知らせ" />

        <section className="PageTitle" id="pagetop">
          <div class="wrap">
            <img src={ girl3 } className="girls_img"/>
            <img src={ girl17 } className="girls_img"/>
            <div>
              <h2>[　Information　]</h2>
              <h1>アプリに関するお知らせや<br></br>キャンペーン情報など</h1>
            </div>
            <img src={ girl21 } className="girls_img"/>
            <img src={ girl15 } className="girls_img"/>
          </div>
          <div className="area">
            <ul class="circles">
              <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
            </ul>
          </div>
        </section>
        <section className="InfoList">
          <h2>
          お知らせがありません。
          </h2>
        </section>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="お知らせ" />

      <section className="PageTitle" id="pagetop">
        <div class="wrap">
          <img src={ girl3 } className="girls_img"/>
          <img src={ girl17 } className="girls_img"/>
          <div>
            <h2>[　Information　]</h2>
            <h1>アプリに関するお知らせや<br></br>キャンペーン情報など</h1>
          </div>
          <img src={ girl21 } className="girls_img"/>
          <img src={ girl15 } className="girls_img"/>
        </div>
        <div className="area">
          <ul class="circles">
            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
          </ul>
        </div>
      </section>
      
      <section className="InfoList">
        <div className="wrap">

          <ol>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            let img

            if( node.frontmatter.avatar ){
              img = <Image fixed={node.frontmatter.avatar.childImageSharp.fixed} alt={node.frontmatter.title}/>
            }
            return (
              <Link to={node.fields.slug}>
                <li key={node.fields.slug}>
                  <article className="post-list-item" key={node.fields.slug}>
                    <div className="posts__image_container">
                      {img}
                    </div>
                    <header>
                      <span>{node.frontmatter.date}</span>
                      <h2>
                        <span itemProp="headline">{title}</span>
                      </h2>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </li>
              </Link>
            )
          })}
          </ol>
        </div>
      </section>
    </Layout>
  )
}


export default InfoPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            description
            tags
            category
            avatar {
              childImageSharp {
                fixed(width: 298, height: 170) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`